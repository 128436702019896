import React, {Component} from 'react'
import { Link } from 'gatsby'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter} from "shards-react";
import ProjectsData from '../components/Projects.json'
//import Slider from '../components/Slider';
import Flickity from 'react-flickity-component'

import "../components/Nearby.css";
import "../components/GeneralModal.css";

import BeaconAnim from "../images/beaconAnim.mp4";
import HomeScreen from '../images/HomeScreen.mp4';

import homeN from '../images/1Home1.gif'
import detailsN from '../images/2detailinfo.gif'
import profileN from '../images/3profile.gif'

const images = [homeN, detailsN, profileN];
const titles = ["Home", "Details", "Profile"];


const NearbyModal = props => (
  <ModalBody className="GeneralIntro">
    {ProjectsData.map((projectData, index) =>{

      if (index === 1){
        const sections = [projectData.home, projectData.details, projectData.profile];
        return(
          <div className="generalFormat" key={index}>

            <div className="introContainer">
            <div className="imgIntro nearby">
              <div className="iconContainer"><img src={require('../images/NearbyIcon.png')} className="projectIcon"/></div>
              <div className="introTxt">
                <h4>{projectData.type}</h4>
                <h2>{projectData.title}</h2>
                <p>{projectData.intro}</p>
                </div>
              </div>
            </div>

            <div className="infoContent">
            <br/>
            <br/>
            <h2>Background</h2>
            <p>{projectData.background}</p>

            <br/>
            <br/>
            <h2>Design</h2>
            <p>{projectData.design}</p>
            <p>{projectData.design2}</p>
            <div className="wave"></div>

            <p>{projectData.design3}</p>

            <div className="scrollImg"></div>

            <br/>
            <br/>
            <br/>
            <br/>
            <div className="sliderNear">
            <h2>The App</h2>

            <Flickity className="nearSlider"
              options={{
                autoPlay: false,
                pauseAutoPlayOnHover: true,
                wrapAround: true,
                fullscreen: false,
                adaptiveHeight: true,
              }}
            >
              {images.map((image,indexImg) => {
                if (indexImg === 0){
                  return(
                    <div className="sliderDiv" key={indexImg}>

                        <div className="sliderColumn left">
                          <h2>{titles[indexImg]}</h2>
                          <p>{sections[indexImg]}</p>

                            <div className="beaconAnim">
                              <video autoPlay="autoPlay" loop="loop" muted="muted" playsInline crossOrigin="anonymous">
                                <source src={BeaconAnim} type="video/mp4"/>
                              </video>
                            </div>

                        </div>

                        <div className="sliderColumn right">
                          <div className="phoneHolder">
                            <video autoPlay="autoPlay" loop="loop" muted="muted" playsInline crossOrigin="anonymous">
                              <source src={HomeScreen} type="video/mp4"/>
                            </video>
                          </div>
                        </div>

                    </div>
                  )
                }else{
                  return(
                    <div className="sliderDiv" key={indexImg}>

                        <div className="sliderColumn left">
                          <h2>{titles[indexImg]}</h2>
                          <p>{sections[indexImg]}</p>

                        </div>
                        <div className="sliderColumn right">
                          <img src={image} className="iphoneImg"/>
                        </div>

                    </div>
                  )
                }
              })}

            </Flickity>




            </div>

            <div className="theappnear">
              {images.map((image,indexImg) => {
                if (indexImg === 0){
                  return (
                    <div key={indexImg}>
                    <h2>{titles[indexImg]}</h2>
                    <p>{sections[indexImg]}</p>
                    <div className="beaconAnimmobileN">
                      <video autoPlay="autoPlay" loop="loop" muted="muted" playsInline crossOrigin="anonymous">
                        <source src={BeaconAnim} type="video/mp4"/>
                      </video>
                    </div>
                    <div className="phoneHolderMobile">
                      <video autoPlay="autoPlay" loop="loop" muted="muted" playsInline crossOrigin="anonymous">
                        <source src={HomeScreen} type="video/mp4"/>
                      </video>
                    </div>

                    </div>
                  )
                }else {
                  return(
                    <div key={indexImg}>
                      <br/><br/><br/><br/><br/><br/>
                      <h2>{titles[indexImg]}</h2>
                      <p>{sections[indexImg]}</p>
                      <div><img src={image} className="iphoneImgNearby"/></div>
                    </div>
                  )
                }

              })}
            </div>

            </div>






          </div>
        )
      }

    })}
  </ModalBody>
)

export default NearbyModal;

/*

*/
