import React, {Component} from 'react'
import { Link } from 'gatsby'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter} from "shards-react";
import ProjectsData from '../components/Projects.json'


import "../components/GeneralModal.css";
import "../components/VRE.css";


const VREModal = props => (
  <ModalBody className="GeneralIntro vre">
    {ProjectsData.map((projectData, index) =>{

      if (index === 3){
        return(
          <div className="generalFormat" key={index}>
            <div className="vreIntro"></div>
            <div className="vretxt">
              <h1>{projectData.title}</h1>
              <h2>{projectData.subtitle}</h2>
            </div>

            <div className="infoContent vreContent">
              <h3>{projectData.type}</h3>
              <p>{projectData.intro}</p>
              <br/>
              <br/>
              <div className="videoVRE1">
                <iframe className ="iframeVRE1" src="https://player.vimeo.com/video/297213668?color=D4AE5F"  frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>

              <br/>
              <br/>
              <div className="rowVRE">
                <div className="rowVRELeft">
                  <br/>
                  <br/>
                  <h2>Escape room</h2>
                  <p>{projectData.escapeRoom1}</p>
                  <p>{projectData.escapeRoom2}</p>
                  <br/>
                  <div className="VREimg">
                    <img src={require('../images/1escaperoom.png')}  width="90%" />
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </div>

              <div className="rowleftVR">
              <br/>
              <br/>
              <br/>
              <br/>

                <div className="vrexplain">
                  <h2>Virtual Reality</h2>
                  <p>{projectData.vr1}</p>
                  <p>{projectData.vr2}</p>
                  <div>
                  </div>

                </div>

              </div>

              <div className="collaborationVRE">
                <h2>Collaboration</h2>
                <br/>
                <br/>
                <p>{projectData.collaboration}<br/>
                <br/><br/><br/><br/>
                <br/></p>

                <div className="topOval"></div>
              </div>


              <div className="designcontainer">


                <div className="rowDesign">
                  <br/><br/>
                  <h2>Design</h2>
                  <p>We designed the world, the puzzle games, and the interaction with the aim to create something different from what we have seen in current escape rooms and played in available VR games.<br/><br/>
                  We went from creating a world with isomatric shapes and gradient colors, to a world that was more realistic trying to evoke the feeling of an adventurer explorer such as Tomb Raider or Indiana Jones</p>
                  <img src={require('../images/4worldDesignGroup.png')}/>
                </div>
                  <div className="ovalv"></div>
                </div>


              <div>
                  <br/><br/><br/>
                  <h2>The Game</h2>
                  <br/><br/>
                  <p>{projectData.design1}</p>
                  <p>{projectData.design2}</p>
                  <p>{projectData.design3}</p>
                  <div className="vreVideoHolder">
                  <div className="videoVRE1">
                    <iframe className ="iframeVRE" src="https://player.vimeo.com/video/297213668?color=D4AE5F"  frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe></div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </div>
              </div>
              <br/><br/><br/><br/>
            </div>
          </div>
        )
      }

    })}
  </ModalBody>
)

export default VREModal;
