import React, {Component} from 'react'
import { Link } from 'gatsby'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter} from "shards-react";
import ProjectsData from '../components/Projects.json'
import Swiper from 'swiper'
import Flickity from 'react-flickity-component'


import gynecology from '../images/Gynecology.png'
import internal from '../images/InternalMedicine.png'
import pediatrics from '../images/Pediatrics.png'
import pharmacology from '../images/Pharmacology.png'
import surgery from '../images/Surgery.png'

import gameGIF from '../images/Juego.gif'
import memoryGIF from '../images/Memoria.gif'
import statisticsGIF from '../images/Estadisticas.gif'
import efiiciencyGIF from '../images/Eficiencia.gif'

import "../components/Projects.css"

const images = [gameGIF, memoryGIF, statisticsGIF, efiiciencyGIF];
const titles = ["Game", "Memory", "Statistics", "Efficiency"];

const AskMed = props => (


  <ModalBody className="Askmed">
  {ProjectsData.map((projectData, index) => {
    console.log("Is shown", props.open);
    if (index == 0 ){
      const sections = [projectData.game, projectData.memory, projectData.statistics, projectData.efficiency];

      var title = projectData
      return(
        <div className="askdiv" key={index}>
          <div className="first">
          <div className="imgIntro askmed">
            <div className="iconContainer"><img src={require('../images/askmedIcon.png')} className="projectIcon"/></div>
            <div className="intro">
              <h4>{projectData.type}</h4>
              <h2>{projectData.title}</h2>
              <p>{projectData.intro}</p>
            </div>
          </div>
        </div>



          <div className = "infoContent">

            <br/>
            <br/>
            <h2>Background</h2>
            <p>{projectData.background}</p>

            <br/>
            <br/>
            <br/>
            <br/>
            <h2>Design</h2>
            <br/>
            <br/>
            <br/>
            <br/>
            <p align="center">{projectData.design1}</p>
            <br/>
            <div className="gridDesign-container">
              <div><img src={gynecology} width="50%" height="50%"/></div>
              <div><img src={internal} width="50%" height="50%"/></div>
              <div><img src={pharmacology} width="51.5%" height="51.5%"/></div>
              <div><img src={surgery} width="51.5%" height="51.5%"/></div>
              <div><img src={pediatrics} width="50%" height="50%"/></div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <p align="center">{projectData.design2}</p>
            <img src={require('../images/dis.gif')} className="modalImg" width="50%" height="60%"/>
            <br/>
            <br/>
            <br/>
            <br/>

            <div className="fullSlider">
            <h2>The app</h2>

            <Flickity className="sliderAsk"
              options={{
                autoPlay: false,
                pauseAutoPlayOnHover: true,
                wrapAround: true,
                fullscreen: false,
                adaptiveHeight: true,
              }}
            >
              {images.map((image, indexImg) => (
                <div className="sliderDiv" key={indexImg}>

                    <div className="sliderColumn left">
                      <h2>{titles[indexImg]}</h2>
                      <p>{sections[indexImg]}</p>
                    </div>
                    <div className="sliderColumn right">
                      <img src={image} className="iphoneImg"/>
                    </div>

                </div>
              ))}

            </Flickity>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>














            </div>

            <div className="mobileSectionsContainer" key={index}>
              {images.map((image, indexSect) =>(
                <div className="mobileSections" key={indexSect}>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <h2>{titles[indexSect]}</h2>
                  <p>{sections[indexSect]}</p>
                  <div key={indexSect}><img src={image} className="iphoneImg"/></div>
                </div>

              ))}

            </div>

          </div>


        </div>
      )
    }
  })}
  </ModalBody>

)


export default AskMed;

/*

*/
