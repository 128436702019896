import React, {Component} from 'react'
import { Link } from 'gatsby'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter} from "shards-react";
import ProjectsData from '../components/Projects.json'
import AmoebaFilm from "../images/AMOEBAFILM.mp4"



import "../components/GeneralModal.css";
import "../components/Amoeba.css";


const AmoebaModal = props => (
  <ModalBody className="GeneralIntro Amoeba">
    {ProjectsData.map((projectData, index) =>{
      if (index === 4){
        return(
          <div className="generalFormat amoebaFormat" key={index}>
            <div className="introContainer">
              <div className="imgIntro amoeba">
                <div className="iconContainer amoebaIcon"><img src={require('../images/amoebaIcon.png')} className="projectIcon"/></div>
                  <div className="introTxt">
                    <h4>{projectData.type}</h4>
                    <h2>{projectData.title}</h2>
                    <p>{projectData.intro}</p>
                  </div>
                </div>
            </div>

            <div className="videoAmoeba">
              <div className="vimeoDiv1 ">
                <iframe className="vimeoFrame" src="https://player.vimeo.com/video/271533084?color=3E51BB" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen>
                </iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>

            <div className="infoContent amoebaContent">
              <div>
                <h2>Background</h2>
                <p>{projectData.background}</p>
                <p>{projectData.background}</p>
                <p>{projectData.background3}</p>
              </div>

              <div>
                <h2>Design</h2>
                <p>{projectData.design}</p>
                <div className="amoeblaFilm">
                  <video autoPlay="autoPlay" loop="loop" muted="muted" playsInline crossOrigin="anonymous">
                    <source src={AmoebaFilm}  type="video/mp4">
                    </source>
                  </video>
                </div>
              </div>


              <div>
                <h2>Use</h2>
                <p>{projectData.use}</p>
                <div className="iPadAMOEBA">
                <video autoPlay="autoPlay" loop="loop" muted="muted" playsInline crossOrigin="anonymous">
                  <source src={require('../images/iPadAMOEBA.mp4')}  type="video/mp4">
                  </source>
                </video>
                </div>
              </div>
            </div>


          </div>
        )
      }

    })}
  </ModalBody>
)

export default AmoebaModal;
