import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import Ionicon from 'react-ionicons'
import MenuButton from '../components/menuButton'
import ReactTypingEffect from 'react-typing-effect';
import Particles from 'react-particles-js';
import CustomParticles from '../components/CustomParticles.js'
import Card from '../components/Card.js'
import MagicLineMenu from 'react-magic-line-menu';
//import Modal from 'react-bootstrap-modal';
//import ModalShards from '../components/ModalShards.js'
import ProjectsData from '../components/Projects.json'

import AskMedModal from '../components/AskMedModal.js'
import NearbyModal from '../components/NearbyModal.js'
import SatmanModal from '../components/SatmanModal.js'
import VREModal from '../components/VREModal.js'
import AmoebaModal from '../components/AmoebaModal.js'
import FulldomeModal from '../components/FulldomeModal.js'

import { Button, Modal, ModalBody, ModalHeader, ModalFooter} from "shards-react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "shards-react";
import "../components/ModalShards.css"
import "../components/portfolio.css"

const allCards = ["askmed","nearby","satman","vre", "amoeba", "dome"];


export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      image: 'name',
      project: 10,
      askState:'true',
      askStateCell:'showCell',
      nearState:'true ',
      nearStateCell:'showCell',
      satState:'true',
      satStateCell:'showCell',
      vreState:'true',
      vreStateCell:'showCell',
      amoebaState:'true',
      amoebaStateCell:'showCell',
      domeState:'true',
      domeStateCell:'showCell',
      allSelected:'menuBtnSelected',
      developerSelected:'menuBtnunselected',
      uxSelected:'menuBtnunselected',
      mediaSelected:'menuBtnunselected',
      openDrop:false,
      selectedValue:'all',
      allState:'state-true',
      developerState:'state-false',
      uxState:'state-false',
      mediaState:'state-false',

    };
    this.toggle = this.toggle.bind(this);
    this.askRef = React.createRef();
    this.onChangeSelected = this.onChangeSelected.bind(this);

  }

  handleClick = (prop) => {
    console.log(prop);
    //alert(project);
  }

  toggle(e) {
    var name = 'name';
    var projectSelected = 10;

    if (typeof(e) == 'undefined' || e == null){
      console.log("is undefined", projectSelected);

    }else{
      name = e.target.getAttribute("id");
      console.log(name);
    }

    this.setState({
      open: !this.state.open,
      image: name,
      project: projectSelected
    });

  }

  toggleDrop(e){
    this.setState(prevState => {
     return { openDrop: !prevState.openDrop };
   });
  }

  renderSwitch(param){
    switch(param) {
      case 0:
        return <AskMedModal ></AskMedModal>;
      case 1:
        return <NearbyModal></NearbyModal>;
      case 2:
        return <SatmanModal></SatmanModal>;
      case 3:
        return <VREModal></VREModal>;
      case 4:
        return <AmoebaModal></AmoebaModal>;
      case 5:
        return <FulldomeModal></FulldomeModal>;
      case 10:
        return <ModalBody>Error</ModalBody>;
      default:
        break;
    }
  }

  renderFooter(modal){
    switch (modal) {
      case 0:
        return (
          <ModalFooter className="askF">
          <div className="container-contact100-form-btn">
            <button className="contact100-form-btn" onClick={this.toggle}>
              <span>
                Ok
              </span>
            </button>
          </div>
          </ModalFooter>
        );
      case 1:
        return (
          <ModalFooter className="nearF">
          <div className="container-contact100-form-btn">
            <button className="contact100-form-btn" onClick={this.toggle}>
              <span>
                Ok
              </span>
            </button>
          </div>
          </ModalFooter>
        );
      case 2:
        return (
          <ModalFooter className="satF">
          <div className="container-contact100-form-btn">
            <button className="contact100-form-btn" onClick={this.toggle}>
              <span>
                Ok
              </span>
            </button>
          </div>
          </ModalFooter>
        );
      case 3:
        return (
          <ModalFooter className="vref">
          <div className="container-contact100-form-btn">
            <button className="contact100-form-btn" onClick={this.toggle}>
              <span>
                Ok
              </span>
            </button>
          </div>
          </ModalFooter>
        );
      case 4:
        return (
          <ModalFooter className="amoebaF">
          <div className="container-contact100-form-btn">
            <button className="contact100-form-btn" onClick={this.toggle}>
              <span>
                Ok
              </span>
            </button>
          </div>
          </ModalFooter>
        );
      case 5:
        return (
          <ModalFooter className="domef">
            <div className="container-contact100-form-btn">
              <button className="contact100-form-btn" onClick={this.toggle}>
                <span>
                  Ok
                </span>
              </button>
            </div>
          </ModalFooter>);
      case 10:
        return (
          <ModalFooter>
          <div className="container-contact100-form-btn">
            <button className="contact100-form-btn" onClick={this.toggle}>
              <span>
                Ok
              </span>
            </button>
          </div>
          </ModalFooter>
        );
      default:
        break;
    }
  }

  menuButtonAction(e){
    this.sortCards(e);
  }

  sortCards(filter){

    this.setState({
      allSelected:'menuBtnunselected',
      developerSelected:'menuBtnunselected',
      uxSelected:'menuBtnunselected',
      mediaSelected:'menuBtnunselected',

      askState:'false',
      askStateCell:'menuBtnunselected',
      nearState:'false',
      nearStateCell:'menuBtnunselected',
      satState:'false',
      satStateCell:'menuBtnunselected',
      vreState:'false',
      vreStateCell:'menuBtnunselected',
      amoebaState:'false',
      amoebaStateCell:'menuBtnunselected',
      domeState:'false',
      domeStateCell:'menuBtnunselected',
    })

    switch (filter) {
      case 0:
        console.log("filter all");
        this.setState({
          allSelected:'menuBtnSelected',
        })

        setTimeout(
          function(){
            this.setState({
              askState:'true',
              nearState:'true ',
              satState:'true',
              vreState:'true',
              amoebaState:'true',
              domeState:'true',

              allState:'state-true',
              developerState:'state-false',
              uxState:'state-false',
              mediaState:'state-false',
            })

          }.bind(this),270)

        break;
      case 1:
        console.log("filter developer");
        this.setState({
          developerSelected:'menuBtnSelected',
        })

        setTimeout(
          function(){
            this.setState({
              askState:'true',
              nearState:'true ',
              domeState:'true',

              allState:'state-false',
              developerState:'state-true',
              uxState:'state-false',
              mediaState:'state-false',
            })

          }.bind(this),270)

        break;
      case 2:
        this.setState({
          uxSelected:'menuBtnSelected',
        })
        console.log("filter ux");
        setTimeout(
          function(){
            this.setState({
              askState:'true',
              amoebaState:'true',
              nearState:'true ',
              vreState:'true',
              domeState:'true',

              allState:'state-false',
              developerState:'state-false',
              uxState:'state-true',
              mediaState:'state-false',
            })

          }.bind(this),270)
        break;
      case 3:
        this.setState({
          mediaSelected:'menuBtnSelected',
        })

        setTimeout(
          function(){
            this.setState({
              satState:'true',

              allState:'state-false',
              developerState:'state-false',
              uxState:'state-false',
              mediaState:'state-true',
            })

          }.bind(this),270)

        console.log("filter media");
        break;
      default:
      console.log('Error in filter');

    }

    /*switch (filter) {
      case 0:
        console.log("filter all");
        this.setState({
          allSelected:'menuBtnSelected',
          developerSelected:'menuBtnunselected',
          uxSelected:'menuBtnunselected',
          mediaSelected:'menuBtnunselected',

          askState:'true',
          askStateCell:'showCell',
          nearState:'true ',
          nearStateCell:'showCell',
          satState:'true',
          satStateCell:'showCell',
          vreState:'true',
          vreStateCell:'showCell',
          amoebaState:'true',
          amoebaStateCell:'showCell',
          domeState:'true',
          domeStateCell:'showCell',
        })
        break;
      case 1:
        this.setState({
          allSelected:'menuBtnunselected',
          developerSelected:'menuBtnSelected',
          uxSelected:'menuBtnunselected',
          mediaSelected:'menuBtnunselected',
          domeStateCell:'menuBtnunselected',
          amoebaStateCell:'menuBtnunselected',
          vreStateCell:'menuBtnunselected',
          satStateCell:'menuBtnunselected',
          nearStateCell:'showCell',
        });
        this.setState({
          askState:'true',
          amoebaState:'false',
          satState:'false',
          nearState:'true ',
          vreState:'false',
          domeState:'true',

        })
        setTimeout(
          function(){
            this.setState({
              askStateCell:'showCell',
              amoebaStateCell:'hideCell',
              satStateCell:'hideCell',
              domeStateCell:'showCell',
              vreStateCell:'hideCell',
              nearStateCell:'showCell',
            });
          }
          .bind(this),270
        );
        break;
      case 2:
      this.setState({
        allSelected:'menuBtnunselected',
        developerSelected:'menuBtnunselected',
        uxSelected:'menuBtnSelected',
        mediaSelected:'menuBtnunselected',
        domeStateCell:'showCell',
        amoebaStateCell:'showCell',
        vreStateCell:'showCell',
        satStateCell:'showCell',
        nearStateCell:'showCell',
        askStateCell:'showCell',
      });
        this.setState({
          satState:'false',
          askState:'true',
          amoebaState:'true',
          nearState:'true ',
          vreState:'true',
          domeState:'true',
        });
        setTimeout(
          function(){
            this.setState({
              satStateCell:'hideCell',
              askStateCell:'showCell',
              amoebaStateCell:'showCell',
              domeStateCell:'showCell',
              vreStateCell:'showCell',
              nearStateCell:'showCell',
            });
          }
          .bind(this),270
        );
        break;
      case 3:
        console.log("filter media");
        this.setState({
          amoebaStateCell:'showCell',
          amoebaState:'true',
          allSelected:'menuBtnunselected',
          developerSelected:'menuBtnunselected',
          uxSelected:'menuBtnunselected',
          mediaSelected:'menuBtnSelected',

        });
        this.setState({
          askState:'false',
          nearState:'false',
          satState:'true',
          vreState:'false',
          amoebaState:'false',
          domeState:'false',
        })
        setTimeout(
          function(){
            this.setState({
              domeStateCell:'hideCell',
              amoebaStateCell:'hideCell',
              vreStateCell:'hideCell',
              satStateCell:'showCell',
              nearStateCell:'hideCell',
              askStateCell:'hideCell',
            });
          }
          .bind(this), 270
        )
        break
      default:
        console.log("error filtering");
    }*/
  }

  onChangeSelected(v){
    console.log("Value:", v.target.value);
    this.setState({selectedValue: v.target.value})
    switch (v.target.value) {
      case 'all':
      this.menuButtonAction(0);
        break;
      case 'developer':
      this.menuButtonAction(1);
        break;
      case 'ux':
      this.menuButtonAction(2);
        break;
      case 'media':
      this.menuButtonAction(3);
        break;
      default:
        console.log("Error ehile filtering");
    }
  }

  render(){
    const { open } = this.state;
    const { image } = this.state;
    const {project} = this.state;
    const {askState} = this.state;

    const allCards = this.allCards;
    const cards = [<div className="cardCell" id={this.state.vreStateCell}>
    <Card
      title="UI/UX"
      text="VRE"
      image={require('../images/VRE.png')}
      action={this.toggle}
      id={this.state.vreState}
      ref={this.askRef}>
    </Card></div>,
    <div className="cardCell" id={this.state.amoebaStateCell}>
      <Card title="UI/UX"
        text="Amoeba"
        image={require('../images/AMOEBA.png')}
        action={this.toggle}
        id={this.state.amoebaState}>
      </Card>
    </div>,
    <div className="cardCell" id={this.state.nearStateCell}>
      <Card title="UI/UX - Developer"
        text="Nearby"
        image={require('../images/NearBooks.png')}
        action={this.toggle}
        id={this.state.nearState}>
      </Card>
    </div>,
    <div className="cardCell" id={this.state.askStateCell}>
      <Card title="UI/UX - Developer"
        text="Askmed"
        image={require('../images/ASKMED.png')}
        action={this.toggle}
        id={this.state.askState}>
      </Card>
    </div>,
    <div className="cardCell" id={this.state.satStateCell}>
      <Card title="Media"
        text="SATMAN"
        image={require('../images/SATMAN.png')}
        action={this.toggle}
        id={this.state.satState}>
      </Card>
    </div>,
    <div className="cardCell" id={this.state.domeStateCell}>
      <Card title="UX/UI - Developer"
        text="Interactive Fulldome"
        image={require('../images/Interactive Fulldome.png')}
        action={this.toggle}
        id={this.state.domeState}>
      </Card>
    </div>
  ];


    return(
      <Layout>
      <CustomParticles/>
        <div className="RightMenu" id="mySidenav">

        <div className="logoHolder">
          < div className="LogoImg" align="center">
            <img src={require('../images/Logo.png')} width="80"/>
          </div>
          <p className="MenuTitle">Erick Hernández</p>
          <p className="MenuSubtitle">Interaction Designer · Developer</p>
          <a href="#" ><Ionicon className ="CloseButton" icon="md-close" onClick={() => document.getElementById("mySidenav").style.width = "0"} color="#F7F7F7"/></a>
        </div>

            <div className="menuContent">
              <nav><Link to="/"><Ionicon icon ="ios-home-outline" color="#F7F7F7" fontSize="20px"/>&ensp; HOME</Link></nav>
              <nav><Link to="/portfolio"><Ionicon icon ="ios-briefcase" color="#F7F7F7" fontSize="23px"/>&ensp;PORTFOLIO</Link></nav>
              <nav><Link to="/resume"><Ionicon icon ="ios-paper-outline" color="#F7F7F7" fontSize="20px"/>&ensp;RESUME</Link></nav>
              <nav><Link to="/about"><Ionicon icon ="ios-contact-outline" color="#F7F7F7" fontSize="20px"/>&ensp;ABOUT</Link></nav>
              <nav><Link to="/contact"><Ionicon icon ="ios-send-outline" color="#F7F7F7" fontSize="20px"/>&ensp;CONTACT</Link></nav>
            </div>

            <div className="bottomgridHolder">
              <div className="bottomGrid">
                <a className="email" href="mailto:hola@erickh.info" align="center">hola@erickh.info</a>
                  <div className="grid-container">
                  <div className="grid-item"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#f7f7f7" onClick={() => window.open('https://www.behance.net/ergihervi')} style={{cursor:'pointer'}}><path d="M232 237.2c31.8-15.2 48.4-38.2 48.4-74 0-70.6-52.6-87.8-113.3-87.8H0v354.4h171.8c64.4 0 124.9-30.9 124.9-102.9 0-44.5-21.1-77.4-64.7-89.7zM77.9 135.9H151c28.1 0 53.4 7.9 53.4 40.5 0 30.1-19.7 42.2-47.5 42.2h-79v-82.7zm83.3 233.7H77.9V272h84.9c34.3 0 56 14.3 56 50.6 0 35.8-25.9 47-57.6 47zm358.5-240.7H376V94h143.7v34.9zM576 305.2c0-75.9-44.4-139.2-124.9-139.2-78.2 0-131.3 58.8-131.3 135.8 0 79.9 50.3 134.7 131.3 134.7 61.3 0 101-27.6 120.1-86.3H509c-6.7 21.9-34.3 33.5-55.7 33.5-41.3 0-63-24.2-63-65.3h185.1c.3-4.2.6-8.7.6-13.2zM390.4 274c2.3-33.7 24.7-54.8 58.5-54.8 35.4 0 53.2 20.8 56.2 54.8H390.4z"/></svg></div>
                    <div className="grid-item"><Ionicon icon="logo-youtube"  color="#F7F7F7" fontSize="20px" onClick={() => window.open('https://www.youtube.com/user/ergihervi/featured')} style={{cursor:'pointer'}}/></div>
                    <div className="grid-item"><Ionicon icon="logo-vimeo"  color="#F7F7F7" fontSize="20px" onClick={() => window.open('https://vimeo.com/ergihervi')} style={{cursor:'pointer'}}/></div>
                    <div className="grid-item"><Ionicon icon="logo-linkedin"  color="#F7F7F7" fontSize="20px" onClick={() => window.open('https://www.linkedin.com/in/ergihervi/')} style={{cursor:'pointer'}}/></div>
                  </div>
              </div>
            </div>



        </div>

        <div className="MainContent">
        <MenuButton action={() => document.getElementById("mySidenav").style.width = "250px"}/>

        <h3 className="PageTitle">portfolio</h3>

        <div className="custom-select">
          <select  value={this.state.selectedValue} onChange={this.onChangeSelected}>
              <option value="all">All</option>
              <option value="developer">Developer</option>
              <option value="ux">UX/UI</option>
              <option value="media">Media</option>
          </select>
        </div>

        <div className="FilterMenu">
          <nav>
            <a href="javascript:void(0)" onClick={this.menuButtonAction.bind(this, 0)} id={this.state.allSelected}>All</a>
            <a href="javascript:void(0)" onClick={this.menuButtonAction.bind(this, 1)} id={this.state.developerSelected}>Developer</a>
            <a href="javascript:void(0)" onClick={this.menuButtonAction.bind(this, 2)} id={this.state.uxSelected}>UX/UI</a>
            <a href="javascript:void(0)" onClick={this.menuButtonAction.bind(this, 3)} id={this.state.mediaSelected}>Media</a>
          </nav>
        </div>

        <div className="layerToMask"></div>



        <div className="cardsHolder allHolder" id={this.state.allState} >
          <div className="Cards">

            {cards.map((card,i) => <div key={i}>{card}</div>)}
              {console.log("Set state:", this.state.askState)}

          </div>
          <div className="footer-cards footer-all">
            <p>Hecho con ♥ en suecia. © 2019 Erick Hernández. All rights reserved.</p>
          </div>
        </div>

        <div className="cardsHolder developerHolder" id={this.state.developerState}>
          <div className="Cards">

            {cards.map((card,i) =>
              {if (i != 0 && i !== 1 && i !== 4){
                console.log("card to render:", i);
                return(<div key={i}>{card}</div>);
              }})
            }
              {console.log("Set state:", this.state.askState)}

          </div>
          <div className="footer-cards footer-dev">
            <p>Hecho con ♥ en suecia. © 2019 Erick Hernández. All rights reserved.</p>
          </div>
        </div>

        <div className="cardsHolder uxHolder" id={this.state.uxState}>
          <div className="Cards">

            {cards.map((card,i) =>
              {if (i !== 4){
                console.log("card to render:", i);
                return(<div key={i}>{card}</div>);
              }})
            }
              {console.log("Set state:", this.state.askState)}

          </div>
          <div className="footer-cards footer-ux">
            <p>Hecho con ♥ en suecia. © 2019 Erick Hernández. All rights reserved.</p>
          </div>
        </div>

        <div className="cardsHolder mediaHolder" id={this.state.mediaState} >
          <div className="Cards">

            {cards.map((card,i) =>
              {if (i == 4){
                console.log("media to render:", i);
                return(<div key={i}>{card}</div>);
              }})
            }

          </div>
          <div className="footer-cards footer-media">
            <p>Hecho con ♥ en suecia. © 2019 Erick Hernández. All rights reserved.</p>
          </div>
        </div>

        <div>

          {ProjectsData.map((projectData, index) => {
            if (projectData.title === this.state.image){
              console.log(index)
              //this.state.project = index;
              this.project = index
            }
          })}

          <Modal size="xl" open={open} toggle={this.toggle}>

            {console.log("State:", /*this.state.open*/ this.open)}
            {console.log("project to render", /*this.state.project*/this.project)}
            {this.renderSwitch(/*this.state.project*/ this.project)}
            {this.renderFooter(this.project)}

          </Modal>


        </div>

        <div className="pageFooter portfolio-footer">
          <p>Hecho con ♥ en suecia. © 2019 Erick Hernández. All rights reserved.</p>
        </div>



        </div>

      </Layout>

    );
  }
}

/*

id={this.state.mediaState}

<div className="cardCell" id={this.state.amoebaStateCell}>
  <Card title="UI/UX"
    text="Amoeba"
    image={require('../images/AMOEBA.png')}
    action={this.toggle}
    id={this.state.amoebaState}>
  </Card>
</div>

<div className="cardCell" id={this.state.nearStateCell}>
  <Card title="UI/UX - Developer"
    text="Nearby"
    image={require('../images/NearBooks.png')}
    action={this.toggle}
    id={this.state.nearState}>
  </Card>
</div>

<div className="cardCell" id={this.state.askStateCell}>
  <Card title="UI/UX - Developer"
    text="Askmed"
    image={require('../images/ASKMED.png')}
    action={this.toggle}
    id={this.state.askState}>
  </Card>
</div>

<div className="cardCell" id={this.state.satStateCell}>
  <Card title="Media"
    text="SATMAN"
    image={require('../images/SATMAN.png')}
    action={this.toggle}
    id={this.state.satState}>
  </Card>
</div>

<div className="cardCell" id={this.state.domeStateCell}>
  <Card title="UX/UI - Developer"
    text="Interactive Fulldome"
    image={require('../images/Interactive Fulldome.png')}
    action={this.toggle}
    id={this.state.domeState}>
  </Card>
</div>


*/
