import React, {Component} from 'react'
import ReactDOM from "react-dom";
import { Link } from 'gatsby'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter} from "shards-react";
import ProjectsData from '../components/Projects.json'

import Flickity from 'react-flickity-component'

import "../components/GeneralModal.css";
import "../components/Fulldome.css";

import startCircle from '../images/1Start_circle.gif'
import startInfinity from '../images/1.2Start_infinity.gif'
import startCancel from '../images/1.3cancel.gif'

import grabObjectimg from '../images/2.1GrabObject.gif'
import moveObjectimg from '../images/2.2moveObject.gif'
import moveObjectCloseimg from '../images/2.3MoveObjectClose.gif'
import moveObjectAwayimg from '../images/2.4MoveObjectAway.gif'
import realeaseObjectimg from '../images/2.5RealeaseObject.gif'

import moveCameraLeft from '../images/3.1moveCameraLeft.gif'
import moveCameraRight from '../images/3.2moveCameraRight.gif'
import moveCameraForward from '../images/3.3moveCameraForward.gif'
import moveCameraBackwards from '../images/3.4moveCameraBackwards.gif'

import 'flickity/dist/flickity.min.css';


const images = [startCircle, startInfinity, startCancel];
const objManImg = [grabObjectimg, moveObjectimg, moveObjectCloseimg, moveObjectAwayimg, realeaseObjectimg];
const camManImg = [moveCameraLeft, moveCameraRight, moveCameraForward, moveCameraBackwards];



const titles = ["Trigger Gestures", "Specific Gestures: Manipulation", "Specific Gestures: Movement"]
//const Slider = require('../components/Slider');
const flickityOptions = {
  autoPlay: false,
  pauseAutoPlayOnHover: true,
  wrapAround: true,
  fullscreen: false,
  adaptiveHeight: true,
  lazyLoad: 2,
}

function Carousel() {
  return (
    <Flickity
    options={flickityOptions}
    reloadOnUpdate>
      <img src="https://placeimg.com/640/480/animals" />
      <img src="https://placeimg.com/640/480/animals" />
    </Flickity>
  );
}

 function componentDidMount (){
  console.log(":DDDD");
}

function CaregenesModal(){
  return(

  <ModalBody className="GeneralIntro FulldomeModal">
    {ProjectsData.map((projectData, index) =>{
      if (index === 5){
        const sections = [projectData.triggerGestures1, projectData.specificGestures1, projectData.specificGestures1];
        const figCaption = [projectData.figTriggerCaption1, projectData.figTriggerCaption2, projectData.figTriggerCaption3];
        const figCaptionMan = [projectData.specificGrabGesture, projectData.specificMoveObject ,  projectData.specificMoveObjectClose ,  projectData.specificMoveObjectAway ,  projectData.specificRealease];
        const figCaptionCam = [projectData.specificMoveCameraLeft, projectData.specificMoveCameraRight ,  projectData.specificMoveCameraForward ,  projectData.specificMoveCameraBackwards];



        return(
          <div className="generalFormat" key={index}>

          <div className="introContainer">
          <div className="imgIntro fulldome">
            <div className="fuldomeIconContainer"><img src={require('../images/InteractiveFulldomeIcon.png')} className="fulldomeIcon"/></div>
            <div className="introTxt fulldomeIntroTxt">
              <h4>{projectData.type}</h4>
              <h2>{projectData.title}</h2>
              <p>{projectData.intro}</p>
              </div>
            </div>
          </div>

            <div className="infoContent fulldomeContent">
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <h2>Background</h2>
              <div className="fulldomeNews">
                <p>{projectData.background1}</p>
                <p>{projectData.background2}</p>
                <p>{projectData.background3}</p>
              </div>

              <div className="fulldomeDesign">
                <h2>Design</h2>
                <p>{projectData.design1}</p>

                <div className="fulldomeComp1">
                  <p>
                    <img src={require('../images/fulldomeComp1.png')}/>
                    <br/>
                    <br/>
                    {projectData.design2}
                  </p>
                </div>

                <div className="gulldomeComp2">
                  <p >
                  <img src={require('../images/fulldomeComp2.png')}/>
                  <br/>
                  <br/>
                  {projectData.design3}
                  </p>

                </div>

              </div>


              <div>
                <h2>Process</h2>
                <p>After the brainstorming, this method was used to categorize the ideas. We used the double diamond model to design the solution, as it encourages you to gather information at any stage, considering that we needed to understand a very specific technology with very specific users.</p>

                <div className="doubleDiamond">
                  <img src={require('../images/DoubleDiamond.png')} alt="The Double Diamond"></img>
                </div>

                <p>In each stage we used different methods to understand, define, and select the best options.</p>

                <div className="domeprocessgrid-container">
                  <div className="item1">
                    <div className="brain">
                      <h2>Brainstorming</h2>
                      <p>We used this method to explore the different angles and directions to go. Each session consisted on 10 minutes where each member wrote every idea in a sticky note and after the set time passed every idea was explained.</p>
                    </div>
                  </div>

                  <div className="item2"><div className="dot">
                    <h2>Dot Voting</h2>
                    <p>Once we categorized the ideas with the affinity diagram, we used dot voting to select the best ones. To do so each member of the team had 3 votes to choose our favorite ideas. Everyone can voted using a marker to make a dot on the sticky note corresponding to the idea selected.</p>
                  </div></div>

                  <div className="item3"><div>
                    <h2>Research</h2>
                    <p>We gather information in order to understand the domain and the possible challenges from different type of literature. Additionally we perform interviews and questionaries to people that are expert in the field, and some bodystorming sessions to understand better the space and some possible gestures.</p>
                  </div></div>

                  <div className="item4"><div>
                    <h2>Weighted matrix</h2>
                    <p>This method was used to understand the priorities and select the best technology for the prototype.</p>
                  </div></div>

                  <div className="item5"><div>
                      <h2>Affinity Diagram</h2>
                      <p>After the brainstorming, this method was used to categorize the ideas.</p>
                  </div></div>

                  <div className="item6"><div>
                      <h2>Expert Interview</h2>
                      <p>An interview with people expert in the field was performed in the VisualizationCenter-C in order to understand the requirements for designing for a fulldome and the technology.</p>
                  </div></div>
                </div>

              </div>

              <div>

                  <div className="gesturesGridRow">
                    <div className="columnGestures gesturesLeft">
                      <div className="leapmotionhands"></div>
                    </div>
                    <div className="columnGestures gesturesRight">
                      <div className="columnGesturesText">
                        <h2>Gestures</h2>
                        <p>We defined two different type of gestures: trigger and specific gestures</p>
                      </div>
                    </div>
                  </div>

                <h5>Trigger Gestures</h5>
                <p>These gestures are perform by drawing a specific shape that will determine the following action, which can be followed by an specific gesture or that just end a task.</p><br/>

                <Flickity className="domeSlider"
                options={{
                  autoPlay: false,
                  pauseAutoPlayOnHover: true,
                  wrapAround: true,
                  fullscreen: false,
                  adaptiveHeight: true,
                  lazyLoad: 2,

                }}
                reloadOnUpdate={true}>
                {images.map((image, indexImg) => (
                  <div className="sliderDivDome" key={indexImg}>
                    <figure>
                      <img src={image}/>
                      <figcaption>{figCaption[indexImg]}</figcaption>
                    </figure>

                  </div>
                ))}

                </Flickity>




                <br/><br/><br/><br/><br/><br/>
                <h5>Specific Gestures: Manipulation</h5>
                <p>These gestures are designed to manipulate an object and they can only be accessed by the circle gesture.</p><br/>

                <Flickity className="domeSlider"
                options={{
                  autoPlay: false,
                  pauseAutoPlayOnHover: true,
                  wrapAround: true,
                  fullscreen: false,
                  adaptiveHeight: true,
                  lazyLoad: 2,

                }}
                reloadOnUpdate={true}>
                {objManImg.map((image2, indexImg2) => (
                  <div className="sliderDivDome" key={indexImg2}>
                    <figure>
                      <img src={image2}/>
                      <figcaption>{figCaptionMan[indexImg2]}</figcaption>
                    </figure>

                  </div>
                ))}

                </Flickity>

                <br/><br/><br/><br/><br/><br/>
                <h5>Specific Gestures: Camera control</h5>
                <p>These gestures are designed to control the position and view of the camera, and they can only be accessed by the knot gesture.</p><br/>

                <Flickity className="domeSlider"
                options={{
                  autoPlay: false,
                  pauseAutoPlayOnHover: true,
                  wrapAround: true,
                  fullscreen: false,
                  adaptiveHeight: true,
                  lazyLoad: 2,

                }}
                reloadOnUpdate={true}>
                {camManImg.map((image3, indexImg3) => (
                  <div className="sliderDivDome" key={indexImg3}>
                    <figure>
                      <img src={image3}/>
                      <figcaption>{figCaptionCam[indexImg3]}</figcaption>
                    </figure>

                  </div>
                ))}

                </Flickity>


                <br/><br/>  <br/><br/>   <br/><br/>

              </div>









              <div>
                <h2>Prototype</h2>
                <p>The prototype developed was tested in different locations: in The Media Lab, a room placed in a building named Kuggen from Chalmers in Lindholmen, Gothenburg; in the Visual Arena, a studio that has one of the largest 4K monitors in Europe; and in the VR-arena, a room in the VisualizationCenter-C (Visualiseringscenter-C) in Norrköping.</p>
                <div>
                  <div className="vimeoDivDome">
                    <iframe src="https://player.vimeo.com/video/373858696" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                  </div>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>

                <br/><br/><br/><br/>
              </div>



            </div>

          </div>
        )
      }

    })}
  </ModalBody>
)}

export default CaregenesModal;

/*
import Slider from '../components/Slider';

<Slider className="domeSlider"
  options={{
    autoPlay: false,
    pauseAutoPlayOnHover: true,
    wrapAround: true,
    fullscreen: false,
    adaptiveHeight: true,
    lazyLoad: 2,
  }}
>
  {images.map((image, indexImg) => (
    <div className="sliderDivDome" key={indexImg}>
      <figure>
        <img src={image}/>
        <figcaption>{figCaption[indexImg]}</figcaption>
      </figure>

    </div>
  ))}

</Slider>

<Slider className="domeSlider"
  options={{
    autoPlay: false,
    pauseAutoPlayOnHover: true,
    wrapAround: true,
    fullscreen: false,
    adaptiveHeight: true,
    lazyLoad: 2,
  }}
>
  {objManImg.map((image2, indexImg2) => (
    <div className="sliderDivDome" key={indexImg2}>
      <figure>
        <img src={image2}/>
        <figcaption>{figCaptionMan[indexImg2]}</figcaption>
      </figure>

    </div>
  ))}

</Slider>

<Slider className="domeSlider"
  options={{
    autoPlay: false,
    pauseAutoPlayOnHover: true,
    wrapAround: true,
    fullscreen: false,
    adaptiveHeight: true,
    lazyLoad: 2,
  }}
>
  {camManImg.map((image3, indexImg3) => (
    <div className="sliderDivDome" key={indexImg3}>
      <figure>
        <img src={image3}/>
        <figcaption>{figCaptionCam[indexImg3]}</figcaption>
      </figure>

    </div>
  ))}

</Slider>
*/
