import React, {Component} from 'react'
import { Link } from 'gatsby'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter} from "shards-react";
import ProjectsData from '../components/Projects.json'


import "../components/GeneralModal.css";
import "../components/SATMANModal.css";

const SatmanModal = props => (
  <ModalBody className="GeneralIntro satmanModal">
    {ProjectsData.map((projectData, index) =>{

      if (index === 2){
        return(
          <div className="generalFormat" key={index}>

            <div className="introContainer">
            <div className="imgIntro satman">
              <div className="iconContainer"><img src={require('../images/SATMANLogo.png')} className="projectIcon"/></div>
              <div className="introTxt">
                <h4>{projectData.type}</h4>
                <h2>{projectData.title}</h2>
                <p>{projectData.intro}</p>
                </div>
              </div>
            </div>

            <div className="infoContent satmanContent">
            <div className="videoHolder">
              <iframe className="vimeo-player" src="https://player.vimeo.com/video/365508945?color=34AF65" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div><script src="https://player.vimeo.com/api/player.js"></script>


            <br/>
            <br/>
            <h2>Design</h2>
            <p>{projectData.design1}</p>

            <div className="animHolder">
              <img src={require('../images/satmanAnim.gif')}/>
            </div>

            <br/>
            <br/>
            <p>{projectData.details}</p>

          </div>
          </div>
        )
      }

    })}
  </ModalBody>
)

export default SatmanModal;
